import useSWR from 'swr';

import useQueryString from '@/hooks/useQueryString';
import { fetcher } from '@/helpers/fetcher';
import { API_HOSTNAME as API, API_TABLE_TYPE as T, ACTION_GET_TYPE as G } from '@/constants/api';
import { ENABLE_GUEST_PERSONALIZATION } from '@/constants/feature-flags';
import { getItem } from '@helpers/localstorage';
import useGroupLink from '@hooks/useGroupLink';

/**
 * @function useGuestInfo
 * @description a custom hook to get guest information
 */
function useGuestInfo(code) {
  const { getQueryValue } = useQueryString();
  const isGroup = useGroupLink();
  const currentCode = code || getQueryValue('code') || (isGroup && getItem('guestCode')) || '';

  const name = (isGroup && getItem('guestName')) || getQueryValue('to', '');
  const shift = getQueryValue('shift', '');
  const partner = Number(`${ENABLE_GUEST_PERSONALIZATION ? getQueryValue('partner', '') || 1 : 2}`);
  const isRegistered = Boolean(currentCode || name);
  let finalURL = null;

  if (isRegistered) {
    finalURL = API + `?action=${G.readQrCode}&table_name=${T.rsvp}&guest_code=${currentCode}`;
  }

  const DEFAULT_EMPTY_GUEST = {
    name,
    shift,
    guest_quota: partner,
    isLoading: true,
  };

  const { data, isLoading, error } = useSWR(finalURL, fetcher);
  const rawData = data?.data || {};
  const guestData = {
    code: rawData.CODE || '',
    name: rawData.NAME || name || '',
    desc: rawData.DESC || '',
    shift: rawData.SHIFT || '',
    address: rawData.ADDRESS || '',
    country_code: rawData.COUNTRY_CODE || '',
    phone_number: rawData.PHONE_NUMBER || '',
    email: rawData.EMAIL || '',
    guest_quota: rawData.GUEST_QUOTA || partner,
    guest_confirm: rawData.GUEST_CONFIRM || 0,
    guest_lift: rawData.GUEST_LEFT || 0,
    status: rawData.STATUS || '',
    system_info: rawData.SYSTEM_INFO || '',
    last_update: rawData.LAST_UPDATE || '',
  };
  if (error || !data?.success || isLoading) {
    return { ...DEFAULT_EMPTY_GUEST, isLoading, isRegistered: false };
  }
  // By default, we always validate NAME & CODE for better security
  // we only required the first 3 characters for shortest link
  if (
    guestData.name.toLowerCase() === decodeURIComponent(name).toLowerCase() ||
    (isGroup && !isRegistered)
  ) {
    return { ...guestData, isLoading, isRegistered };
  }
  return { ...DEFAULT_EMPTY_GUEST, isLoading, isRegistered: false };
}

export default useGuestInfo;
