export const BG_COVER = `https://ik.imagekit.io/masansgaming/Winda%20Robbi/cover_cDfhDWo7u.jpg?updatedAt=1702868419269`;
export const BG_RSVP = `https://ik.imagekit.io/masansgaming/Winda%20Robbi/Pengganti%20foto%202_HxthrDGbF.jpg?updatedAt=1703679670935`;
export const BG_LIVESTREAM = `https://ik.imagekit.io/invitatoid/template-rainajanssen/bg-pre-livestream_REXdy4RJy.jpg?updatedAt=1638541152599`;
export const BG_FOOTER = `https://ik.imagekit.io/masansgaming/Winda%20Robbi/footer_QgSqIQF0x.jpg?updatedAt=1702868418680`;
export const BG_START_JOURNEY =
  'https://ik.imagekit.io/masansgaming/Winda%20Robbi/lovestory_05iuWvLr2l.jpg?updatedAt=1702868418858';
export const BG_COUNTING = `https://ik.imagekit.io/masansgaming/Winda%20Robbi/Pengganti%20Foto%201_o4edccL4b.jpg?updatedAt=1703679671195`;
export const BG_LOVESTORY = `https://ik.imagekit.io/masansgaming/Winda%20Robbi/lovestory_05iuWvLr2l.jpg?updatedAt=1702868418858`;
export const BG_FILTER = `https://ik.imagekit.io/masansgaming/Winda%20Robbi/COVER%20WINDA%20ROBBI_zo4E7wKcZ.jpg?updatedAt=1702889792283`;
export const BG_DESKTOP_VIEW = `https://ik.imagekit.io/masansgaming/Winda%20Robbi/desktop_H4odTM0A-.jpg?updatedAt=1702868417966`;

// The Bride photos
export const IMG_MAN = `https://ik.imagekit.io/masansgaming/Winda%20Robbi/groom_gWr0ahJae.jpg?updatedAt=1702868418480`;
export const IMG_GIRL = `https://ik.imagekit.io/masansgaming/Winda%20Robbi/bride_ZjbkeUUZb.jpg?updatedAt=1702868422075`;

// Cover gallery
export const IMG_COVER_1 = `https://ik.imagekit.io/masansgaming/Winda%20Robbi/gal%201_2V23FHwqu.jpg?updatedAt=1702868418109`;
export const IMG_COVER_2 = `https://ik.imagekit.io/masansgaming/Winda%20Robbi/gal%202_zvm9liI_w.jpg?updatedAt=1702868419267`;
export const IMG_COVER_3 = `https://ik.imagekit.io/masansgaming/Winda%20Robbi/gal%203_493l6zgTaK.jpg?updatedAt=1702868418403`;

// Don't Change with CDN image
import imgQR from '@/images/qrcover.jpg';
export const IMG_QR_COVER = imgQR;

// Invitato global assets
export const LOGO_INVITATO = `https://ik.imagekit.io/invitatoid/global-assets/invitato_hs3nyqsuwy_.png?updatedAt=1638541184695`;
